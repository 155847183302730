












































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { getScreenSizeVariant } from '@/vue-app/utils/screen';
import ContractSavingsProfilingPoliticallyExposedPerson
  from '@/vue-app/components/contract-savings/profiling/ContractSavingsProfilingPoliticallyExposedPerson.vue';
import CustomAutocomplete from '@/vue-app/components/CustomAutocomplete.vue';
import ContractSavingsProfilingWorkAllianzViewModel
  from '@/vue-app/view-models/components/contract-savings/profiling/work/contract-savings-profiling-work-allianz-view-model';

// Domain

@Component({
  name: 'ContractSavingsProfilingWorkAllianz',
  components: {
    ContractSavingsProfilingPoliticallyExposedPerson,
    ContractSavingsProfilingPoliticallyExposedPersonError:
      () => import('@/vue-app/components/contract-savings/profiling/politically-exposed-person/ContractSavingsProfilingPoliticallyExposedPersonError.vue'),
    CustomAutocomplete,
    ContractSavingsActions: () => import('@/vue-app/components/contract-savings/ContractSavingsActions.vue'),
  },
})
export default class ContractSavingsProfilingWorkAllianz extends Vue {
  profiling_work_allianz_view_model = Vue.observable(
    new ContractSavingsProfilingWorkAllianzViewModel(),
  );

  @Watch('profiling_work_allianz_view_model.is_loading')
  onLoadChange(is_loading: boolean) {
    this.$emit('loadingInfo', is_loading);
  }

  @Watch('profiling_work_allianz_view_model.inputs.labor_shift')
  onLaborShiftChange(labor_shift: any) {
    if (labor_shift.desc_actividad.toLowerCase().trim() === 'otro' || labor_shift.desc_actividad.toLowerCase().trim() === 'otros') {
      this.profiling_work_allianz_view_model.active_other_labor_shift = true;
    } else {
      this.profiling_work_allianz_view_model.active_other_labor_shift = false;
      this.profiling_work_allianz_view_model.inputs.other_labor_shift = '';
    }
  }

  @Watch('profiling_work_allianz_view_model.inputs.job')
  onJobChange(job: any) {
    if (job.desc_ocupacion.toLowerCase() === 'otro' || job.desc_ocupacion.toLowerCase() === 'otros') {
      this.profiling_work_allianz_view_model.active_other_job = true;
    } else {
      this.profiling_work_allianz_view_model.active_other_job = false;
      this.profiling_work_allianz_view_model.inputs.other_job = '';
    }
  }

  prevStep() {
    this.$emit('prevStep');
  }

  async nextStep() {
    if (this.profiling_work_allianz_view_model.qualify_as_pep === 'yes') {
      this.profiling_work_allianz_view_model.internal_step = 2;
    } else {
      const save_step = await this.profiling_work_allianz_view_model.saveStep();
      if (save_step) {
        this.$emit('nextStep');
      }
    }
  }

  endProcess() {
    this.$emit('endProcess');
  }

  async created() {
    this.profiling_work_allianz_view_model.small_screen = getScreenSizeVariant() === 'small';
    await this.profiling_work_allianz_view_model.initialize();
  }
}

